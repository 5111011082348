import { AxiosPromise } from 'axios';
import API from './api';
import * as JournalsClosing from '../../model/shop/shop/journalClosing';
import * as JournalsEdit from '../../model/shop/shop/journalEdit';
import * as messagesOrder from '../../model/shop/messagesOrder';
import * as OrderCart from '../../model/shop/table_session/orderCart';
import * as OrderDelete from '../../model/shop/table_session/orderDelete';
import * as OrderDeleteTmp from '../../model/shop/table_session/orderDeleteTmp';
import * as OrderEdit from '../../model/shop/table_session/orderEdit';
import * as OrderServed from '../../model/shop/table_session/orderServed';
import * as OrderEditTmp from '../../model/shop/table_session/orderEditTmp';
import * as OrderHistory from '../../model/shop/table_session/orderHistory';
import * as OrderHome from '../../model/shop/table_session/orderHome';
import * as OrderIndex from '../../model/shop/table_session/orderIndex';
import * as OrderMenu from '../../model/shop/table_session/orderMenu';
import * as OrderOrder from '../../model/shop/table_session/orderOrder';
import * as PaymentEdit from '../../model/shop/table_session/paymentEdit';
import * as PaymentQuick from '../../model/shop/table_session/paymentQuick';
import * as PrintersEdit from '../../model/shop/printersEdit';
import * as PrintersIndex from '../../model/shop/printersIndex';
import * as PrintersSettled from '../../model/shop/shop/printerSettled';
import * as PrintersStatus from '../../model/shop/printersStatus';
import * as ReceiptReceipt from '../../model/shop/table_session/receiptReceipt';
import * as ReceiptSettle from '../../model/shop/table_session/receiptSettle';
import * as SettingIndex from '../../model/shop/shop/settingIndex';
import * as StaffsIndex from '../../model/shop/staffsIndex';
import * as PrintRulesIndex from '../../model/shop/printRulesIndex';
import * as TableSessionCancel from '../../model/shop/table_session/tableSessionCancel';
import * as TableSessionPaying from '../../model/shop/table_session/tableSessionPaying';
import * as TableSessionRecepted from '../../model/shop/table_session/tableSessionRcepted';
import * as TableSessionList from '../../model/shop/table_session/tableSession';
import * as TableSessionView from '../../model/shop/table_session/tableSessionView';
import * as TableSessionViewQuick from '../../model/shop/table_session/tableSessionViewQuick';
import * as TableSessionQuick from '../../model/shop/table_session/tableSessionQuick';
import * as TableSessionMoveSeat from '../../model/shop/table_session/tableSessionMoveSeat';
import * as TableSessionQrReissue from '../../model/shop/table_session/tableSessionQrReissue';
import * as TableSessionQrCode from '../../model/shop/table_session/tableSessionQrCode';
import * as TakeoutChangeFlag from '../../model/shop/shop/takeoutChangeFlag';
import * as TakeoutDelete from '../../model/shop/takeoutDelete';
import * as TakeoutEdit from '../../model/shop/takeoutEdit';
import * as TakeoutIndex from '../../model/shop/takeoutIndex';
import * as TakeoutList from '../../model/shop/takeoutList';
import * as NotificationTimeLimit from '../../model/shop/table_session/notificationTimeLimit';
import * as TableSessionEffect from '../../model/shop/table_session/tableSessionEffect';
import * as TableGroup from '../../model/tableGroup';
import * as LastOrderTime from '../../model/shop/shop/lastOrderTime';
import * as SoldoutIndex from '../../model/shop/soldoutIndex';
import * as SoldoutEdit from '../../model/shop/soldoutEdit';
import * as SoldoutAll from '../../model/shop/soldoutAll';
import * as DrawerOpen from '../../model/shop/drawerOpen';
import * as DisplayIndex from '../../model/shop/displayIndex';
import * as DisplayCheck from '../../model/shop/displayCheck';
import * as DisplayStart from '../../model/shop/displayStart';
import * as DisplayOpen from '../../model/shop/displayOpen';
import * as DisplayPay from '../../model/shop/displayPay';
import * as DisplayEnd from '../../model/shop/displayEnd';
import * as OrderList from '../../model/shop/order_list/orderList';

class ShopAPI extends API {
  /**
   * トークン設定
   */
  public set_token(token: string) {
    if (token) {
      this.headers.Authorization = `Bearer ${token}`;
    } else {
      this.headers.Authorization = '';
    }
  }

  // 【来店客の処理系】
  // /v1/hallstaff/table_sessions/table-sessions/index テーブルセッション一覧取得（HOME)
  public table_sessions(): AxiosPromise<TableSessionList.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions.json', 'GET', null, null);
  }
  // /v1/hallstaff/table_sessions/table-sessions/view テーブルセッション詳細取得（会計）
  public table_sessions_view(query: TableSessionView.requestQuery): AxiosPromise<TableSessionView.response>  {
    return super.request(`/hallstaff/table_sessions/table-sessions/view/${query.table_session_id}`, 'GET', null, null);
  }
  // /v1/hallstaff/table_sessions/table-sessions/view-quick テーブルセッション詳細取得（会計）QuickOrder
  public table_sessions_view_quick(query: TableSessionViewQuick.requestQuery): AxiosPromise<TableSessionViewQuick.response>  {
    return super.request(`/hallstaff/table_sessions/table-sessions/view-quick/${query.table_session_id}`, 'GET', null, null);
  }
  // /v1/hallstaff/table_sessions/table-sessions/cancel 受付を取り消す
  public table_sessions_cancel(args: TableSessionCancel.request): AxiosPromise<TableSessionCancel.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/cancel.json', 'PUT', args, null);
  }
  // /v1/hallstaff/table_sessions/table-sessions/paying 精算処理中にする
  public table_sessions_paying(args: TableSessionPaying.request): AxiosPromise<TableSessionPaying.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/paying.json', 'PUT', args, null);
  }
  // /v1/hallstaff/table_sessions/table-sessions/recepted 受付中にする
  public table_sessions_recepted(args: TableSessionRecepted.requestQuery): AxiosPromise<TableSessionRecepted.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/recepted.json', 'PUT', args, null);
  }
  // /v1/hallstaff/table_sessions/table-sessions/quick QuickOrder
  public table_sessions_quick(args: TableSessionQuick.request): AxiosPromise<TableSessionQuick.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/quick.json', 'POST', args, null);
  }
  // /v1/hallstaff/table_sessions/table-sessions/start limit time
  public start_limit_time(args: TableSessionEffect.request): AxiosPromise<TableSessionEffect.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/start.json', 'PUT', args, null);
  }
  // /v1/hallstaff/table_sessions/orders/index 注文一覧取得
  public order_home(query: OrderHome.requestQuery): AxiosPromise<OrderHome.response>  {
    return super.request(`/hallstaff/table_sessions/orders/home/${query.table_session_id}`, 'GET', null, null);
  }
  // /v1/hallstaff/OrderList/list.json 注文一覧取得
  public order_list(args: OrderList.request): AxiosPromise<OrderList.response>  {
    return super.request('/hallstaff/order_list/list.json', 'POST', args, null);
  }
  // /v1/hallstaff/table_sessions/order/index オーダーテーブル更新
  public order_index(args: OrderIndex.request): AxiosPromise<OrderIndex.response>  {
    return super.request('/hallstaff/table_sessions/orders/index.json', (args.order.id) ? 'PUT' : 'POST', args.order, null);
  }
  // /v1/hallstaff/table_sessions/orders/menu 新規メニュー作成、オーダーテーブル更新
  public order_menu(args: OrderMenu.request): AxiosPromise<OrderMenu.response>  {
    return super.request('/hallstaff/table_sessions/orders/menu.json', 'POST', args, null);
  }
  // /v1/hallstaff/table_sessions/orders/cart 注文確認一覧取得 ※ODR-792
  public order_cart(query: OrderCart.requestQuery): AxiosPromise<OrderCart.response>  {
    return super.request(`/hallstaff/table_sessions/orders/cart/${query.table_session_id}`, 'GET', null, null);
  }
  // /v1/hallstaff/table_sessions/orders/order オーダーテーブル注文済みに更新
  public order_order(args: OrderOrder.request): AxiosPromise<OrderOrder.response>  {
    return super.request('/hallstaff/table_sessions/orders/order.json', 'POST', args, null);
  }
  // /v1/hallstaff/table_sessions/orders/edit-tmp オーダーテーブル個数、オプション更新(tmp)
  public order_edit_tmp(args: OrderEditTmp.request): AxiosPromise<OrderEditTmp.response>  {
    return super.request('/hallstaff/table_sessions/orders/edit-tmp.json', 'POST', args.order, null);
  }
  // /v1/hallstaff/table_sessions/orders/delete-tmp オーダーテーブル削除(tmp)
  public order_delete_tmp(args: OrderDeleteTmp.request): AxiosPromise<OrderDeleteTmp.response>  {
    return super.request('/hallstaff/table_sessions/orders/delete-tmp.json', 'POST', args.order, null);
  }
  // /v1/hallstaff/table_sessions/orders/history 注文履歴一覧取得
  public order_history(query: OrderHistory.requestQuery): AxiosPromise<OrderHistory.response>  {
    return super.request(`/hallstaff/table_sessions/orders/history/${query.table_session_id}`, 'GET', null, null);
  }
  // /v1/hallstaff/table_sessions/orders/edit オーダーテーブル個数、オプション更新
  public order_edit(args: OrderEdit.request): AxiosPromise<OrderEdit.response>  {
    return super.request('/hallstaff/table_sessions/orders/edit.json', 'POST', args.datas, null);
  }
  // /v1/hallstaff/table_sessions/orders/served 配膳フラグ更新
  public order_served(args: OrderServed.request): AxiosPromise<OrderServed.response>  {
    return super.request('/hallstaff/table_sessions/orders/served.json', 'POST', args.datas, null);
  }
  // /v1/hallstaff/table_sessions/orders/delete オーダーテーブル削除
  public order_delete(args: OrderDelete.request): AxiosPromise<OrderDelete.response>  {
    return super.request('/hallstaff/table_sessions/orders/delete.json', 'POST', args.order, null);
  }
  // /v1/hallstaff/table_sessions/payments/edit 支払い登録
  public payment_edit(args: PaymentEdit.request, query: PaymentEdit.requestQuery): AxiosPromise<PaymentEdit.response>  {
    return super.request(`/hallstaff/table_sessions/payments/edit/${query.payment_id}`, 'PUT', args.payment, null);
  }
  // /v1/hallstaff/table_sessions/payments/quick 支払い登録（QuickOrder）
  public payment_quick(args: PaymentQuick.request, query: PaymentQuick.requestQuery): AxiosPromise<PaymentQuick.response>  {
    return super.request(`/hallstaff/table_sessions/payments/quick/${query.payment_id}`, 'PUT', args, null);
  }
  // /v1/hallstaff/table_sessions/receipt/settle レシート印刷
  public receipt_settle(args: ReceiptSettle.request): AxiosPromise<ReceiptSettle.response>  {
    return super.request('/hallstaff/table_sessions/receipt/settle.json', 'POST', args.payment, null);
  }
  // /v1/hallstaff/table_sessions/receipt/receipt 領収書印刷
  public receipt_receipt(args: ReceiptReceipt.request): AxiosPromise<ReceiptReceipt.response>  {
    return super.request('/hallstaff/table_sessions/receipt/receipt.json', 'POST', args, null);
  }

  // /v1/hallstaff/table_sessions/table_sessions/notification-time-limit 領収書印刷
  public notification_time_limit(): AxiosPromise<NotificationTimeLimit.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/notification-time-limit.json', 'GET', null, null);
  }

  // 【 店舗管理系 】
  // /v1/hallstaff/shop/printers/settled 精算リスト一覧取得 ※ODR-793
  public printers_settled(query: PrintersSettled.requestQuery): AxiosPromise<PrintersSettled.response>  {
    return super.request('/hallstaff/shop/printers/settled.json', 'GET', null, query);
  }
  // /v1/hallstaff/shop/journals/closing 締め処理一覧取得
  public journals_closing(): AxiosPromise<JournalsClosing.response>  {
    return super.request(`/hallstaff/shop/journals/closing`, 'GET', null, null);
  }
  // /v1/hallstaff/shop/journals/edit 締め処理の実行
  public journals_edit(args: JournalsEdit.request, query: JournalsEdit.requestQuery): AxiosPromise<JournalsEdit.response>  {
    return super.request(`/hallstaff/shop/journals/edit/${query.journal_id}`, 'POST', args.journal, null);
  }
  // /v1/hallstaff/shop/takeout/change-flag テイクアウトの受付中と停止中を切り替える
  public takeout_change_flag(args: TakeoutChangeFlag.request): AxiosPromise<TakeoutChangeFlag.response>  {
    return super.request('/hallstaff/shop/takeout/change-flag.json', 'PUT', args, null);
  }
  // 店舗メッセージ
  public message_order(): AxiosPromise<messagesOrder.response> {
    return super.request('/hallstaff/shop/messages/order.json', 'GET', null, null);
  }
  // 店舗設定
  public shop_setting(): AxiosPromise<SettingIndex.response> {
    return super.request('/hallstaff/shop/setting.json', 'GET', null, null);
  }
  // ラストオーダー時間
  public shop_setting_last_order_time(args: LastOrderTime.request): AxiosPromise<SettingIndex.response> {
      return super.request('/hallstaff/shop/setting/last_order_time.json',  'POST', args, null);
  }
  // リセット時間
  public shop_setting_reset_time(args: LastOrderTime.request): AxiosPromise<SettingIndex.response> {
      return super.request('/hallstaff/shop/setting/reset_time.json',  'POST', args, null);
  }
  // /v1/hallstaff/shop/soldout/index SOLDOUT一覧取得
  public soldout_index(): AxiosPromise<SoldoutIndex.response>  {
    return super.request(`/hallstaff/shop/soldout/index.json`, 'GET', null, null);
  }
  // /v1/hallstaff/shop/soldout/edit SOLDOUTを変更する
  public soldout_edit(query: SoldoutEdit.requestQuery): AxiosPromise<SoldoutEdit.response>  {
    return super.request(`/hallstaff/shop/soldout/edit/${query.menu_id}/${query.is_soldout}`, 'PUT', null, null);
  }
  // /v1/hallstaff/shop/soldout/all すべてのSOLDOUTをOFFにする
  public soldout_all(): AxiosPromise<SoldoutAll.response>  {
    return super.request(`/hallstaff/shop/soldout/all.json`, 'PUT', null, null);
  }
  // IOT設定
  public shop_iot_setting(): AxiosPromise<SettingIndex.response> {
    return super.request('/hallstaff/initialize.json', 'GET', null, null);
  }
  // /v1/hallstaff/shop/deisplay/index カスタマーディスプレイ
  public display_index(): AxiosPromise<DisplayIndex.response>  {
    return super.request(`/hallstaff/shop/display/index.json`, 'GET', null, null);
  }
  // /v1/hallstaff/shop/deisplay/check カスタマーディスプレイcheck
  public display_check(): AxiosPromise<DisplayCheck.response>  {
    return super.request(`/hallstaff/shop/display/check`, 'GET', null, null);
  }
  // /v1/hallstaff/shop/deisplay/start カスタマーディスプレイstart
  public display_start(query: DisplayStart.requestQuery): AxiosPromise<DisplayStart.response>  {
    return super.request(`/hallstaff/shop/display/start`, 'GET', null, query);
  }
  // /v1/hallstaff/shop/deisplay/open カスタマーディスプレイopen
  public display_open(query: DisplayOpen.requestQuery): AxiosPromise<DisplayOpen.response>  {
    return super.request(`/hallstaff/shop/display/open`, 'GET', null, query);
  }
  // /v1/hallstaff/shop/deisplay/pay カスタマーディスプレイpay
  public display_pay(query: DisplayPay.requestQuery): AxiosPromise<DisplayPay.response>  {
    return super.request(`/hallstaff/shop/display/pay`, 'GET', null, query);
  }
  // /v1/hallstaff/shop/deisplay/end カスタマーディスプレイend
  public display_end(query: DisplayEnd.requestQuery): AxiosPromise<DisplayEnd.response>  {
    return super.request(`/hallstaff/shop/display/end`, 'GET', null, query);
  }

  // 【テイクアウト系】
  // /v1/hallstaff/takeout/index テイクアウト取得（アイコン表示対象）
  public takeout_index(query: TakeoutIndex.requestQuery): AxiosPromise<TakeoutIndex.response>  {
    return super.request('/hallstaff/takeout/index.json', 'GET', null, query);
  }
  // /v1/hallstaff/takeout/list テイクアウト注文一覧取得
  public takeout_list(query: TakeoutList.requestQuery): AxiosPromise<TakeoutList.response>  {
    return super.request(`/hallstaff/takeout/list`, 'GET', null, query);
  }
  // /v1/hallstaff/takeout/edit テイクアウトを受取済にする
  public takeout_edit(query: TakeoutEdit.requestQuery): AxiosPromise<TakeoutEdit.response>  {
    return super.request(`/hallstaff/takeout/edit/${query.table_session_id}`, 'PUT', null, null);
  }
  // /v1/hallstaff/takeout/delete 注文キャンセル
  public takeout_delete(args: TakeoutDelete.request, query: TakeoutDelete.requestQuery): AxiosPromise<TakeoutDelete.response>  {
    return super.request(`/hallstaff/takeout/delete/${query.table_session_id}`, 'DELETE', args, null);
  }
  // 【プリンタ系】
  // /v1/hallstaff/printers/receipt 印刷履歴取得
  public printers_index(): AxiosPromise<PrintersIndex.response>  {
    return super.request('/hallstaff/printers/index.json', 'GET', null, null);
  }
  // /v1/hallstaff/printers/status プリンタの状態取得
  public printers_status(): AxiosPromise<PrintersStatus.response>  {
    return super.request('/hallstaff/printers/status.json', 'GET', null, null);
  }
  // /v1/hallstaff/printers/jobs プリンタジョブの更新
  public printers_edit(args: PrintersEdit.request, query: PrintersEdit.requestQuery): AxiosPromise<PrintersEdit.response>  {
    return super.request(`/hallstaff/printers/edit/${query.print_job_id}`, 'PUT', args.print_job, null);
  }
  // スタッフ一覧取得
  public staffs_index(): AxiosPromise<StaffsIndex.response>  {
    return super.request('/hallstaff/staffs.json', 'GET', null, null);
  }
  // 印刷ルール一覧取得
  public print_rules_index(): AxiosPromise<PrintRulesIndex.response>  {
    return super.request('/hallstaff/printrules.json', 'GET', null, null);
  }
  // Api get table list
  public tables_index(): AxiosPromise<TableGroup.response>  {
    return super.request('/hallstaff/tables', 'GET', null, null);
  }
  // Api get table list
  public checkIn(args: any): AxiosPromise<any>  {
    return super.request('/hallstaff/tables/check-in', 'POST', args, null);
  }
  // Api get table list
  public editQuantities(args: any): AxiosPromise<any>  {
    return super.request('/hallstaff/tables/edit-quantities', 'POST', args, null);
  }
  // 席移動
  public move_seat(args: TableSessionMoveSeat.request): AxiosPromise<TableSessionMoveSeat.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/move_seat.json', 'POST', args, null);
  }
  // QR再発行
  public qr_reissue(args: TableSessionQrReissue.request): AxiosPromise<TableSessionQrReissue.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/qr_reissue.json', 'POST', args, null);
  }
  // ドロワーを開く
  public drawer_open(args: {print_rule_id: string}): AxiosPromise<DrawerOpen.response>  {
    return super.request('/hallstaff/shop/drawer/open.json', 'POST', args, null);
  }
  // QR取得
  public qrcode(args: TableSessionQrCode.request): AxiosPromise<TableSessionQrCode.response>  {
    return super.request('/hallstaff/table_sessions/table_sessions/qrcode.json', 'POST', args, null);
  }
}

export default ShopAPI;
