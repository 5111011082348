export class ShopSetting {
  has_guide: boolean;
  contract_plan: number;
  printer_plan: boolean;
  is_orderlist: boolean;
  is_select_receive: boolean;
  is_receipt: boolean;
  last_order_times: lastOrderTime[];
  reset_time: string;
  is_enable: boolean;
}

export class lastOrderTime {
    id: string;
    time: string;
    release_time: string;
    hour: string;
    minute: string;
    is_enable: boolean;
}

export enum ContractPlans {
  TAKEOUT = 1,
  SELFORDER = 2,
  HYBRID = 3,
}
