import Vuex from 'vuex';
import Vue from 'vue';
import _ from 'lodash';
import { ulid } from "ulid";

import { ContractPlans, ShopSetting } from '../model/entity/shopSetting';
import { PlaySound } from '../common/util/playsound';
import { StorageServiceBuilder } from '../services/Storage/storageServiceBuilder';
import { TableSession } from '../model/entity/table_session';
import ShopAPI from '../common/api/api_shop';
import { Category } from '../model/entity/category';
import { NotificationTimeLimit } from '@/model/entity/notificationTimeLimit';
import {CategoryGroup} from "../model/entity/category_group";

import { Table } from "@/model/entity/table";
import { SessionStatus } from "../model/entity/tableSessionSetting";
import { Order } from '@/model/entity/order';

const api = new ShopAPI();

Vue.use(Vuex);

const shopSetting: ShopSetting = null;
const categories: Category[] = [];
const categoryGroups: CategoryGroup[] = [];
const tableSessions: TableSession[] = [];
const tableGroups: Table[] = [];
const tableSession: TableSession = null;
const printerStatus: string|null[] = [];
const takeoutCount: number = 0;
const takeoutSessions: TableSession[] = [];
const takeoutIsOpen: boolean = false;
const takeoutSearch: string = "";
const notificationTimeLimit: NotificationTimeLimit[] = [];
const notificationLimitClose: string[] = [];
const shopIotLink: string = "";
const shopId: string = "";

const version = '0.001';
const storeKey = `cache.shop-${version}`;
const throttleWaiting = 150;

const shopStore = new Vuex.Store({
  state: {
    wsUrl: '',
    token: '',
    shoppix: '',
    shopSetting,
    tableSessions,
    categories,
    categoryGroups,
    tableGroups,
    tableSession,
    printerStatus,
    staff: {
      id: '',
      nickname: '',
    },
    printRule: {},
    printerName: {},
    takeoutCount,
    takeoutSessions,
    takeoutIsOpen,
    takeoutSearch,
    latestOrder: {
      order: '0000000000',
      takeout: '0000000000',
    },
    notificationTimeLimit,
    soundOn: true,
    sound: {
      order: true,
      takeout: true,
    },
    notificationLimitClose,
    shopIotLink,
    shopId,

    langKey: 'ja_JP',
    staticTranslates: {} as { [key: string]: { [key: string]: string } },
    langs: [] as { name: string, locale: string, is_enable: boolean }[],
    previousPage: 'home',
    statusDisplay: [SessionStatus.STATUS_RECEPTED, SessionStatus.STATUS_PAYING],

    meta: {
      isLoadingShopSetting: false,
      isLoadingShopIotSetting: false,
      isLoadingTableSession: false,
      isLoadingLanguage: false,
    },
    selectedCategory: Category,
    pageChange: {
      tableList: '',
      menu: '',
      order: '',
      check: '',
      value: ''
    },
    customerDisplay: {
      total: '0',
      cash: '0',
      change: '0',
    },
    orderList: [] as Order[],
    loading: false,
  },
  getters: {
    canUseEatIn(state): boolean {
      const setting = state.shopSetting;
      if (setting === null) {
        return false;
      }
      switch (state.shopSetting.contract_plan) {
        case ContractPlans.TAKEOUT:
          return false;
        case ContractPlans.SELFORDER:
          return true;
        case ContractPlans.HYBRID:
          return true;
      }

      // invalid
      return false;
    },
    canUseTakeout(state): boolean {
      const setting = state.shopSetting;
      if (setting === null) {
        return false;
      }

      switch (state.shopSetting.contract_plan) {
        case ContractPlans.TAKEOUT:
          return true;
        case ContractPlans.SELFORDER:
          return false;
        case ContractPlans.HYBRID:
          return true;
      }

      // invalid
      return false;
    },
    canUsePrinter(state, getter): boolean {
      const setting = state.shopSetting;
      if (setting === null) {
        return false;
      }
      const canUseEatIn = getter.canUseEatIn;
      if (canUseEatIn) {
        return true;
      }

      return state.shopSetting.printer_plan;
    }
  },

  actions: {
    setApiToken({ commit }, token: string) {
      commit('setApiToken', token);
    },

    fetchShopSetting: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);
      commit('setIsLoadingShopSetting', true);

      return api.shop_setting()
        .then((res) => {
          if (res.status !== 200) {
            throw new Error('fail to fetch shop setting');
          }
          if (res.data.status != 'success') {
            throw new Error('fail to fetch shop setting');
          }

          commit('setShopSetting', res.data.data);
          commit('setIsLoadingShopSetting', false);
          commit('save');

          return res;
        });
    }, throttleWaiting),
    fetchShopIotSetting: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);
      commit('setIsLoadingShopIotSetting', true);

      return api.shop_iot_setting()
        .then((res) => {
          if (res.status !== 200) {
            throw new Error('fail to fetch shop iot setting');
          }
          if (res.data.status != 'success') {
            throw new Error('fail to fetch shop iot setting');
          }

          commit('setShopIotSetting', res.data.data);
          commit('setIsLoadingShopIotSetting', false);
          commit('save');

          return res;
        });
    }, throttleWaiting),
    fetchTableSessions: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);
      commit('setIsLoadingTableSessin', true);

      return api.table_sessions()
        .then((res) => {
          if (res.data.status !== 'success') {
            throw new Error(res.data.message);
          }

          commit('setTableSessions', res.data.data.table_sessions);
          commit('setTableGroups', res.data.data.table_lists);
          commit('setIsLoadingTableSessin', false);
          commit('save');
        });
    }, throttleWaiting),
    fetchPrinterStatus: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);

      return api.printers_status()
        .then((res) => {
          let errors = [];
          if (res.data.status !== 'success') {
            if (res.data.data.hasErrors && res.data.data.hasErrors.length > 0) {
              res.data.data.hasErrors.forEach(function(e) {
                const langKey = state.langKey;
                const staticTranslate = state.staticTranslates[langKey];
                errors.push(staticTranslate["hall-0027"] + ':' + e.name + '  ' + staticTranslate["hall-0028"]  + ':' + e.code);
              });
            }
            if (res.data.data.errors && res.data.data.errors.length > 0) {
              res.data.data.errors.forEach(function(e) {
                  errors.push(e.message);
              });
            }
          }
          commit('setPrinterStatus', errors);
          commit('save');
        });
    }, throttleWaiting),
    fetchCheckTakeout: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);

      return api.takeout_index({ is_receive: 0 })
        .then((res) => {
          let errors = [];
          if (res.data.status !== 'success') {
            throw Error(res.data.message);
          }

          const data = res.data.data;
          const count = (data.table_sessions || []).length;

          commit('setTakeoutCount', count);
          commit('save');
        });
    }, throttleWaiting),
    fetchTakeoutSearch({ commit, state }, { word }) {
      commit('setTakeoutSearch', word);
      commit('save');
    },
    fetchShopMessage: _.throttle(async ({ commit, state }) => {
      if (state.soundOn === false) {
        return;
      }
      api.set_token(state.token);

      return api.message_order()
        .then((response) => {
          if (response.data.status !== 'success') {
            throw new Error(response.data.message);
          }

          const messages = response.data.data.messages;
          return messages;
        })
        .then((messages) => {
          const takeout = messages.takeout_order || {timestamp : "0000000000"};
          const order = messages.order || {timestamp : "0000000000"};
          // takeout
          if (state.sound.takeout === true) {
            if (state.latestOrder.takeout < takeout.timestamp) {
              PlaySound.play('takeout_order');
            }
          }
          // eatin
          if (state.sound.order === true) {
            if (state.latestOrder.order < order.timestamp) {
              PlaySound.play('order');
            }
          }
          return {
            takeout_order: takeout,
            order: order,
          }
        })
        .then((messages) => {
          // save
          if (state.sound.takeout === true) {
            commit('setLatestTakeoutOrder', messages.takeout_order.timestamp);
          }
          if (state.sound.order === true) {
            commit('setLatestOrder', messages.order.timestamp);
          }
          commit('save');
        })
    }, throttleWaiting),
    fetchOrderList: _.throttle(async ({ commit, state }, { served }) => {
      commit("loading", true);
      api.set_token(state.token);

      return api.order_list({served: served}).then((response) => {
        if (response.data.status === "success") {
          commit('setOrderList', response.data.orders);
        } else {
          commit('clearOrderList');
        }
      }).finally(() => {
        commit("loading", false);
        commit('save');
      });
    }, throttleWaiting),
    fetchTakeoutSessions: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);

      return api.takeout_list({ query: state.takeoutSearch })
        .then((res) => {
          if (res.data.status !== 'success') {
            throw new Error(res.data.message);
          }

          commit('setTakeoutSessions', res.data.tableSessions);
          commit('setTakeoutIsOpen', res.data.isOpen);
          commit('save');
        });
    }, throttleWaiting),
    fetchNotificationTimeLimit: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);

      return api.notification_time_limit()
        .then((res) => {
          if (res.data.status !== 'success') {
            throw new Error(res.data.message);
          }
          commit('setNotificationTimeLimit', res.data.data.notificationTimeLimit);
        });
    }, throttleWaiting),
    fetchPayments: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);

      return api.display_index()
        .then((res) => {
          if (res.data.status !== 'success') {
            throw new Error(res.data.message);
          }
          const data = res.data.data || { customer_displays: null };
          commit('setCustomerDisplay', data.customer_displays);
          commit('save');
        });
    }, throttleWaiting),
    fetchDisplays: _.throttle(async ({ commit, state }) => {
      api.set_token(state.token);

      return api.display_check()
        .then((res) => {
          if (res.data.status !== 'success') {
            throw new Error(res.data.message);
          }
          const data = res.data.data || { customer_displays: null };
          commit('setCustomerDisplay', data.customer_displays);
          commit('save');
        });
    }, throttleWaiting),
    setTableGroups({ commit }, data) {
      commit('setTableGroups', data);
      commit('save');
    },
    setStatusDisplay({ commit }, data) {
      commit('setStatusDisplay', data);
      commit('save');
    },
    setPreviousPage({ commit }, data) {
      commit('setPreviousPage', data);
      commit('save');
    },
    logout({ commit }) {
      commit('setShopSetting', null);
      commit('setShopIotSetting', null);
      commit('setApiToken', '');
      commit('save');
    },

    init({ commit }) {
        commit('setIsLoadingLanguage', true);

        return api.translate({})
            .then((response) => {
                commit('setIsLoadingLanguage', false);
                if (response.data.status != 'success') {
                    throw new Error('fail to fetch static translate data');
                }

                commit('save');

                return response.data.data;
            })
            .then((data) => {
                commit('setLangs', data.languages);
                commit('setStaticTranslate', data.static);
            })
    },

    /**
     * 言語設定を初期化する
     */
    initLangKey({ commit, state }) {
        if (!state.langKey) {
            commit('setInitLangKey');
        }
    },
    selectLangKey({ commit }, langKey: string) {
        commit('setLangKey', langKey);
        commit('save');
    },
    setLastOrderTime({ commit }, data) {
        commit('setLastOrderTime', data);
        commit('save');
    },
    setResetTime({commit}, data) {
      commit('setResetTime', data);
      commit('save');
    },

    getWsUrl({commit}) {
      api.getSignedWss().then(response => {
        if (response.data.status != 'success') {
          throw new Error('fail to get ws url');
        }
        commit('setWsUrl', response.data.data.signed_url);
      })
    },
  },

  mutations: {
    /**
     * ローカルストレージへの保存処理
     */
    save(state) {
      if (!state.shoppix) {
        return;
      }

      const dataStr = JSON.stringify(state);
      StorageServiceBuilder.Instance().save(storeKey + state.shoppix, dataStr);
    },

    /**
     * ローカルストレージからの読み込み処理
     */
    load(state, shoppix: string) {
      console.log('load', shoppix);
      const dataStr = StorageServiceBuilder.Instance().load(storeKey + shoppix);
      if (dataStr) {
        const store = JSON.parse(dataStr);
        this.replaceState(Object.assign(state, store));
      }
      state.shoppix = shoppix;
    },

    setApiToken(state, token: string) {
      state.token = token;
    },
    setShopSetting(state, setting: ShopSetting) {
      state.shopSetting = setting;
    },
    setShopIotSetting(state, data) {
      if (data === null) {
        state.shopIotLink = null;
        state.shopId = null;
      } else {
        state.shopIotLink = data.iotLink;
        state.shopId = data.shopId;
      }
    },
    setTableSessions(state, tsessions: TableSession[]) {
      state.tableSessions =  tsessions;
    },
    setTableGroups(state, tgroups) {
      state.tableGroups = tgroups;
    },
    setCategoryGroups(state, data) {
      state.categoryGroups = data;
    },
    setCategories(state, data) {
      state.categories = data;
    },
    setTableSession(state, tsession: TableSession) {
      state.tableSession =  tsession;
    },
    setPrinterStatus(state, errors) {
      state.printerStatus = errors;
    },
    setStaffId(state, id: string) {
      state.staff.id = id;
    },
    setPrintRuleId(state, data: {bit: string, printRuleId: string | null}) {
      state.printRule[data.bit] = data.printRuleId;
    },
    setPrinterName(state, data: {bit: string, printerName: string | null}) {
      state.printerName[data.bit] = data.printerName;
    },
    setStaffName(state, nickname: string) {
      state.staff.nickname = nickname;
    },
    setTakeoutCount(state, tcount) {
      state.takeoutCount = tcount;
    },
    setTakeoutSessions(state, tsessions: TableSession[]) {
      state.takeoutSessions =  tsessions;
    },
    setTakeoutIsOpen(state, isOpen) {
      state.takeoutIsOpen = isOpen;
    },
    setTakeoutSearch(state, word) {
      state.takeoutSearch = word;
    },
    setLatestTakeoutOrder(state, latest) {
      state.latestOrder.takeout = latest;
    },
    setLatestOrder(state, latest) {
      state.latestOrder.order = latest;
    },
    setNotificationTimeLimit(state, notificationTimeLimit: NotificationTimeLimit[]) {
      state.notificationTimeLimit = notificationTimeLimit;
    },
    setSoundOn(state, isOn) {
      state.soundOn = isOn;
    },
    setSoundOrder(state, sound) {
      state.sound.order = sound;
    },
    setSoundTakeout(state, sound) {
      state.sound.takeout = sound;
    },
    setStatusDisplay(state, status) {
      state.statusDisplay = status;
    },
    setPreviousPage(state, page) {
      state.previousPage = page;
    },
    setCustomerDisplay(state, data) {
      if (data === null) {
          return;
      }
      if ((data.total != null) && (data.total != "")) {
        state.customerDisplay.total = data.total;
      }
      if ((data.cash != null) && (data.cash != "")) {
        state.customerDisplay.cash = data.cash;
      }
      if ((data.change != null) && (data.change != "")) {
        state.customerDisplay.change = data.change;
      }
    },

    /**
     * 表示言語キーを初期化する (日本語)
     */
    setInitLangKey(state) {
        state.langKey = 'ja_JP';
    },
    setLangKey(state, key: string) {
        state.langKey = key;
    },
    setLastOrderTime(state, data) {
        state.shopSetting.last_order_times = data;
    },
    setResetTime(state, data) {
        state.shopSetting.reset_time = data;
    },

    /**
     * 静的翻訳データを保持
     */
    setStaticTranslate(state, staticTranslates: { [key: string]: { [key: string]: string } }) {
        state.staticTranslates = staticTranslates;
    },
    setLangs(state, langs: { name: string, locale: string, is_enable: boolean }[]) {
        state.langs = langs;
    },

    // meta status
    setIsLoadingTableSessin(state, isLoading: boolean) {
      state.meta.isLoadingTableSession = isLoading;
    },
    setIsLoadingShopSetting(state, isLoading: boolean) {
      state.meta.isLoadingShopSetting = isLoading;
    },
    setIsLoadingShopIotSetting(state, isLoading: boolean) {
      state.meta.isLoadingShopIotSetting = isLoading;
    },
    setSelectedCategory(state, category) {
      state.selectedCategory = category;
    },
    setIsLoadingLanguage(state, isLoading: boolean) {
      state.meta.isLoadingLanguage = isLoading;
    },
    setNotificationDisappears(state, notificationId: string) {
      state.notificationLimitClose.push(notificationId);
    },
    setPageChange(state, pageName: string) {
      state.pageChange[pageName] = ulid();
    },
    setPageChangeValue(state, value: string) {
      state.pageChange.value = value;
    },
    setWsUrl(state, url: string) {
      state.wsUrl = url;
    },

    /**
     * オーダーリスト
     */
     setOrderList(state, lists: Order[]) {
      state.orderList = lists;
     },
     clearOrderList(state) {
      state.orderList = [];
     },

     loading(state, value: boolean) {
       state.loading = value;
     },
  },
});

export default shopStore;
