export class PlaySound {
  private static instance: PlaySound = undefined;

  public isUnlocked: boolean = false;
  private soudFiles: {[key: string]: string} = {};
  private sounds: {[key: string]: HTMLAudioElement} = {};

  private constructor(soudFiles: {[key: string]: string}) {
    this.soudFiles = soudFiles;

    this.loadSounds();
  }

  public static load(soudFiles: {[key: string]: string}) {
    if (PlaySound.instance == undefined) {
      PlaySound.instance = new PlaySound(soudFiles);
    }

    return PlaySound.instance;
  }

  private loadSounds() {
    // listen user action
    document.addEventListener('click', this.unlockSound.bind(this));
    document.addEventListener('touchstart', this.unlockSound.bind(this));
  }

  private unlockSound() {
    if (this.isUnlocked) {
      return;
    }

    for (const soundKey in this.soudFiles) {
      const soundFilePath = this.soudFiles[soundKey];

      const audio = new Audio();
      audio.src = soundFilePath;
      audio.muted = true;
      audio.play();
      audio.pause();
      audio.muted = false;
      audio.currentTime = 0;

      this.sounds[soundKey] = audio;
    }

    this.isUnlocked = true;
    document.removeEventListener('click', this.unlockSound);
    document.removeEventListener('touchstart', this.unlockSound);
  }

  public static play(soundKey: string) {
    if (!PlaySound.instance.isUnlocked) {
      console.warn('fail to play sound: unlock process is undergoing');

      return;
    }

    try {
      PlaySound.instance.sounds[soundKey].currentTime = 0;
      PlaySound.instance.sounds[soundKey].volume = 1;

      PlaySound.instance.sounds[soundKey].play()
        .then(() => {
        });
    } catch (err) {
      console.log(err);
    }
  }
}
