import { Menu } from './menu';

export class Category {
  id: string;
  shop_id: string;
  name: string;
  type: number = 1;
  is_emable: boolean = true;
  is_customer: boolean = true;
  rank: number = 10000;

  menus: Menu[];
  formatted_name: string;
}
